import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './landing/landing.component';
import { PathNotFoundComponent } from './path-not-found/path-not-found.component';

// import { HeaderPanelComponent, NavigationDrawerComponent } from './shared';p

import { HttpClientModule } from '@angular/common/http';
/* NgRx */
import { EffectsModule } from '@ngrx/effects';
import { StoreModule, MetaReducer, ActionReducer, State } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
// This cant be imported from index.ts due to Circular Dependency issues.
import { appReducerFn } from './store/app.reducer';
import { AppEffects } from './store/app.effects';

/* App NgRx */
import { environment } from '@environments/environment';

import { FormsModule } from '@angular/forms';
import { SharedModule, NavigationDrawerService } from '../app/shared';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe, TitleCasePipe } from '@angular/common';

/** Interceptor */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppHttpInterceptorService } from './app.interceptor';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { MatIconModule} from '@angular/material/icon';
import { ExistingPolicyComponent } from './shared/components/existing-policy/existing-policy.component';
import { CookieService } from 'ngx-cookie-service';
import { AutoHttpInterceptorService } from '@ffq-app-shared/interceptors/auto.interceptor';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AutoSharedModule } from './auto/shared/shared.module';

import { MAT_SELECT_SCROLL_STRATEGY } from '@angular/material/select';
import { Overlay, BlockScrollStrategy } from '@angular/cdk/overlay';
import {
  PathLocationStrategy,
  LocationStrategy
} from '@angular/common';
import { WindowRef } from '@ffq-app-auto/shared/services/window.ref';
import {MatTableModule} from '@angular/material/table';
import { MultiRetrieveQuoteComponent } from './landing/multi-retrieve-quote/multi-retrieve-quote.component';
import { LayoutModule } from '@angular/cdk/layout';
import {MatListModule} from '@angular/material/list';

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return ((state, action) => {
    return reducer(state, action);
  });
}
export function scrollFactory(overlay: Overlay): () => BlockScrollStrategy {
  return () => overlay.scrollStrategies.block();
}

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    PathNotFoundComponent,
    MultiRetrieveQuoteComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatIconModule,
    MatCheckboxModule,
    FormsModule,
    MatTableModule,
    MatListModule,
    /* NgRx */
    StoreModule.forRoot({ appState: appReducerFn }
      ,
      {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictStateSerializability: false,
          strictActionSerializability: false,
        },
      }
    ),
    EffectsModule.forRoot([AppEffects]),
    StoreDevtoolsModule.instrument({
      name: 'FFQ DevTools',
      maxAge: 25,
      logOnly: environment.production
    }),
    ScrollingModule,
    AutoSharedModule,
    LayoutModule
  ],
  providers: [{
    provide: LocationStrategy,
    useClass: PathLocationStrategy,
  },
    NavigationDrawerService,
    DatePipe, TitleCasePipe,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AppHttpInterceptorService,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AutoHttpInterceptorService,
    multi: true
  },
  WindowRef,
  CookieService,
  { provide: MAT_SELECT_SCROLL_STRATEGY, useFactory: scrollFactory, deps: [Overlay] }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
